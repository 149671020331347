import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HeaderHttpInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let _headers = new HttpHeaders();
    _headers = _headers.append('Content-Type', 'application/json');



    const token = localStorage.getItem('token')  as String;
    if (token !== 'undefined') {
      // console.log('TOKEN:' + token);
      _headers = _headers.append('Authorization', 'Bearer ' + token);
    }

    const authReq = request.clone(
      {
        headers: _headers,
      }
    );



    return next.handle(authReq);
  }
}
