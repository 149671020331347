export class Insurer {

    id: String;
    insurer_code: number;
    name: String;

    constructor(){

    }

}