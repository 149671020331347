import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {ReportSusep} from '../../model/report-susep';
import {BaseComponent} from '../../base/basecomponent';
import {ReportSusepService} from '../../service/report-susep.service';
import {Router} from '@angular/router';
import {Settings} from '../../settings';
import {NgxSpinnerService} from 'ngx-spinner';
import {Filters} from '../../base/filters';

@Component({
    selector: 'app-report-susep',
    templateUrl: './report-susep.component.html',
    styleUrls: ['./report-susep.component.scss'],
    providers: [ReportSusepService]
})
export class ReportSusepComponent extends BaseComponent implements OnInit {

    displayedColumns = ['proposition_number', 'endorsement_number',
        'insured', 'insurer', 'validity_start', 'endorsement_type'];


    dataSource: any;
    model: ReportSusep = new ReportSusep();

    dropdownYear = Filters.createYearsList();

    request = {
        month: (new Date().getMonth() + 1).toString(),
        year: '2019',
        page: 1
    };

    data: any = [];

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ReportSusepService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
    }

    getList(): void {
        this.loading.show();
        this.service.getSusep(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as ReportSusep[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.month = (new Date().getMonth() + 1).toString();
        this.request.year = '2019';
        this.request.page = 1;

        this.dataSource = [];
    }

    getPDF(): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/susep/report/pdf/${this.request.month}/${this.request.year}/?access_token=${token}`;
        window.open(url);
    }

    getExcel(): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/susep/report/excel/${this.request.month}/${this.request.year}/?access_token=${token}`;
        window.open(url);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
