import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {isNullOrUndefined, isUndefined} from 'util';
import {ActivatedRoute, Router} from '@angular/router';
import {Controller} from '../../../model/proposition';
import {BaseComponent} from '../../../base/basecomponent';
import {Policy} from '../../../model/policy';
import {PolicyService} from '../../../service/policy.service';
import {EnumService} from '../../../service/enum.service';
import {WithoutPatternPropositionService} from '../../../service/without-pattern-proposition.service';
import {WithoutPatternPolicy} from '../../../model/withoutPatternPolicy';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-non-standart-form',
  templateUrl: './non-standart-form.component.html',
  styleUrls: ['./non-standart-form.component.scss'],
    providers: [WithoutPatternPropositionService, EnumService]
})
export class NonStandartFormComponent extends BaseComponent implements OnInit {

    model: WithoutPatternPolicy = new WithoutPatternPolicy();
    modelController: Controller = new Controller();

    listController: Controller[] = [];

    listIncome: any = [];
    listActivity: any = [];

    displayedColumns = ['name', 'cpf', 'ppe', 'delete'];
    dataSource: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: WithoutPatternPropositionService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new WithoutPatternPolicy();
            } else {
                this.getProposal(p['hash']);
            }
        });
        this.getListActivity();
        this.getListIncome();
    }

    cancelProposal(): void {
        this.router.navigate(['register-non-standart-policy']);
    }

    onSelectedChange(): void
    {
        // this._mailService.toggleSelectedMail(this.mail.id);
    }

    validate(): boolean {

        if (isNullOrUndefined(this.model.emission)){
            return true;
        }
        if (isNullOrUndefined(this.model.validity_start)){
            return true;
        }
        if (isNullOrUndefined(this.model.validity_end)){
            return true;
        }
        if (isNullOrUndefined(this.model.proposition_number)){
            return true;
        }
        if (isNullOrUndefined(this.model.policy_number)){
            return true;
        }
        if (isNullOrUndefined(this.model.document_number)){
            return true;
        }
        if (isNullOrUndefined(this.model.taker)){
            return true;
        }
        if (isNullOrUndefined(this.model.insured)){
            return true;
        }
        if (isNullOrUndefined(this.model.cpf)){
            return true;
        }
        if (isNullOrUndefined(this.model.zip_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.address)){
            return true;
        }
        if (isNullOrUndefined(this.model.number)){
            return true;
        }
        if (isNullOrUndefined(this.model.district)){
            return true;
        }
        if (isNullOrUndefined(this.model.city)){
            return true;
        }
        if (isNullOrUndefined(this.model.state)){
            return true;
        }
        if (isNullOrUndefined(this.model.ddd)){
            return true;
        }
        if (isNullOrUndefined(this.model.phone)){
            return true;
        }
        if (isNullOrUndefined(this.model.email)){
            return true;
        }
        if (isNullOrUndefined(this.model.birthday)){
            return true;
        }
        if (isNullOrUndefined(this.model.gender)){
            return true;
        }
        if (isNullOrUndefined(this.model.civil_status)){
            return true;
        }
        if (isNullOrUndefined(this.model.vehicle)){
            return true;
        }
        if (isNullOrUndefined(this.model.year)){
            return true;
        }
        if (isNullOrUndefined(this.model.model_year)){
            return true;
        }
        if (isNullOrUndefined(this.model.chassis)){
            return true;
        }
        if (isNullOrUndefined(this.model.license_plate)){
            return true;
        }
        if (isNullOrUndefined(this.model.color)){
            return true;
        }
        if (isNullOrUndefined(this.model.zero_km)){
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement)){
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement_type)){
            return true;
        }
        if (isNullOrUndefined(this.model.endorsement_number)){
            return true;
        }
        if (isNullOrUndefined(this.model.premium)){
            return true;
        }
        if (isNullOrUndefined(this.model.insurer)){
            return true;
        }
        if (isNullOrUndefined(this.model.insurer_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.branch)){
            return true;
        }
        if (isNullOrUndefined(this.model.branch_number)){
            return true;
        }
        if (isNullOrUndefined(this.model.stipulating)){
            return true;
        }
        if (isNullOrUndefined(this.model.importation_id)){
            return true;
        }
        if (isNullOrUndefined(this.model.old_id)){
            return true;
        }
        if (isNullOrUndefined(this.model.denial_date)){
            return true;
        }
        if (isNullOrUndefined(this.model.mx2_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.blocked)){
            return true;
        }
        if (isNullOrUndefined(this.model.ppe)){
            return true;
        }
        if (isNullOrUndefined(this.model.activity_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.income_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.coverage_code)){
            return true;
        }
        if (isNullOrUndefined(this.model.order)){
            return true;
        }
        if (isNullOrUndefined(this.model.updated)){
            return true;
        }
        if (isNullOrUndefined(this.model.ppex)){
            return true;
        }
        return false;
    }

    getProposal(id): void {
            this.loading.show();
        this.service.getWithoutPatternProposalId(id).subscribe(
            data => {
                this.model = data as WithoutPatternPolicy;
                this.listController = this.model.controllers;
                this.dataSource = new MatTableDataSource(this.listController);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    onSave(): void {
        if (!this.validate()) {
            if (isNullOrUndefined(this.model.id)) {
                this.newProposal();
            } else {
                this.editProposal();
            }
        }

    }

    newProposal(): void {
            this.loading.show();
        this.model.controllers = this.listController;
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as WithoutPatternPolicy;
                this.loading.hide();
                this.router.navigate(['register-non-standart-policy']);
            },
            error => {
                super.onError(error);
            });

    }

    editProposal(): void {
            this.loading.show();
        this.model.controllers = this.listController;
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as WithoutPatternPolicy;
                this.loading.hide();
                this.router.navigate(['register-non-standart-policy']);
            },
            error => {
                super.onError(error);
            });

    }

    addController(): void {
        this.listController.push(this.modelController);
        this.dataSource = new MatTableDataSource(this.listController);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.modelController = new Controller();
    }

    removeController(name): void {
        const index = this.listController.findIndex(a => a.name === name);
        this.listController.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.listController);
    }


    getListIncome(): void {
        this.serviceEnum.getIncome().subscribe(
            data => {
                this.listIncome = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListActivity(): void {
        this.serviceEnum.getActivity().subscribe(
            data => {
                this.listActivity = data;
            },
            error => {
                super.onError(error);
            });
    }

}
