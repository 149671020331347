import {User} from './user';
import {Policy} from './policy';

export class Attendance {

    id: string;
    code: string;
    cpf: string;
    name: string;
    email: string;
    license_plate: string;
    policy: string;
    opening: string;
    closure: string;
    status: string;
    reason: Reason;
    reason_id: string;
    user_id: string;
    ddd: string;
    phone: string;
    created_at: string;
    updated_at: string;
    client_id: string;
    user: User;
    observations: Observation[];
    policies: Policy[];

    constructor() {
        this.user = new User();
        this.policies = [];
    }

}

export class Observation {

    audio: boolean;
    audio_sync: Date;
    audio_url: string;
    observation: string;
    attendance_id: string;
    created_at: string;
    user: User;


    constructor() {
        this.audio = true;
        this.user = new User();
    }

}

export class Reason {

    id: string;
    name: string;

    constructor() {

    }

}
