import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard'
    },
    {
        id: 'register',
        title: 'Cadastros',
        type: 'collapsable',
        icon: 'add',
        children: [
            {
                id: 'coverage',
                title: 'Gestão de Cobertura',
                type: 'item',
                icon: '',
                url: '/register-coverage'
            },
            {
                id: 'proposal',
                title: 'Gestão de Propostas',
                type: 'item',
                icon: '',
                url: '/register-proposition'

            },
            {
                id: 'policy',
                title: 'Gestão de Apólices',
                type: 'item',
                icon: '',
                url: '/register-policy'
            },
            /*{
                id: 'non-standard-policy',
                title: 'Gestão de Apólice Fora de Padrão',
                type: 'item',
                icon: '',
                url: '/register-non-standart-policy'
            },*/

        ]
    },
    {
        id: 'reports',
        title: 'Relatórios',
        type: 'collapsable',
        icon: 'equalizer',
        children: [
            {
                id: 'ituran',
                title: 'Ituran',
                type: 'item',
                icon: '',
                url: '/report-ituran'
            },
            {
                id: 'susep',
                title: 'SUSEP',
                type: 'item',
                icon: '',
                url: '/report-susep'
            },
            {
                id: 'renovation',
                title: 'Renovação',
                type: 'item',
                icon: '',
                url: '/report-renovation'
            },

        ]
    },
    // {
    //     id: 'closure',
    //     title: 'Fechamento',
    //     type: 'collapsable',
    //     icon: 'book',
    //     children: [
    //         {
    //             id: 'monthly',
    //             title: 'Fechamento Mensal',
    //             type: 'item',
    //             icon: '',
    //             url: '/closure'
    //         }
    //     ]
    // },
    {
        id: 'import',
        title: 'Importação',
        icon: 'import_contacts',
        type: 'item',
        url: '/import-list',
        // children: [
        //     {
        //         id: 'file',
        //         title: 'Arquivo Mapfre/HDI',
        //         type: 'item',
        //         icon: '',
        //         url: '/import',
        //
        //     }
        // ]
    },
    {
        id: 'crm',
        title: 'CRM',
        icon: 'computer',
        type: 'collapsable',
        children: [
            {
                id: 'register',
                title: 'Cadastrar Cliente',
                type: 'item',
                icon: '',
                url: '/crm-register',
            },
            {
                id: 'raiox',
                title: 'Raio-x Cliente',
                type: 'item',
                icon: '',
                url: '/crm-raiox',
            },

        ]
    },
    {
        id: 'casualty',
        title: 'Sinistro',
        icon: 'directions_car',
        type: 'item',
        url: '/casualty',
        // children: [
        //     {
        //         id: 'report',
        //         title: 'Relatório',
        //         type: 'item',
        //         icon: '',
        //         url: '/casualty',
        //
        //     },
        // ]
    },
    {
        id: 'attendance',
        title: 'Atendimento',
        icon: 'group',
        type: 'item',
        url: '/attendance-list'
    },
    {
        id: 'proposition-forms',
        title: 'Formulários',
        icon: 'assignment',
        type: 'collapsable',
        children: [
            {
                id: 'new_ics',
                title: 'Novo ICS',
                type: 'item',
                icon: '',
                url: '/new-ics',
            },
            {
                id: 'prop_out_pat',
                title: 'Proposta fora de padrão',
                type: 'item',
                icon: '',
                url: '/out-of-pat',
            },
            {
                id: 'end_out_pat',
                title: 'Endosso fora de padrão',
                type: 'item',
                icon: '',
                url: '/end-out-of-pat',
            },
        ]
    },
    {
        id: 'system',
        title: 'Sistema',
        icon: 'settings',
        type: 'collapsable',
        children: [
            {
                id: 'users',
                title: 'Gestão de Usuários',
                type: 'item',
                icon: '',
                url: '/register-user',
            }

        ]
    }
];
