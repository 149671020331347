import {Component, OnInit, ViewChild} from '@angular/core';
import {isNullOrUndefined, isUndefined} from 'util';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Attendance, Observation, Reason} from '../../../model/attendance';
import {BaseComponent} from '../../../base/basecomponent';
import {AttendanceService} from '../../../service/attendance.service';
import {EnumService} from '../../../service/enum.service';
import {User} from '../../../model/user';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-attendance-register',
    templateUrl: './attendance-register.component.html',
    styleUrls: ['./attendance-register.component.scss'],
    providers: [AttendanceService, EnumService]
})
export class AttendanceRegisterComponent extends BaseComponent implements OnInit {

    displayedColumns = ['observation', 'created_at', 'delete'];
    dataSource: any;

    request = {
        user_id: '',
        attendance_id: '',
        page: 1
    };

    model: Attendance = new Attendance();

    modelObservation: Observation = new Observation();

    listStatus: any = [];
    listReason: any = [];

    listObservation: Observation[] = [];

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public loading: NgxSpinnerService,
                public service: AttendanceService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Attendance();
            } else {
                this.getAttendance(p['hash']);
            }
        });

        this.getListReason();
        this.getListStatus();
    }


    validate(): boolean {

        if (isNullOrUndefined(this.model.cpf)) {
            return true;
        }
        if (isNullOrUndefined(this.model.name)) {
            return true;
        }
        if (isNullOrUndefined(this.model.ddd)) {
            return true;
        }
        if (isNullOrUndefined(this.model.phone)) {
            return true;
        }
        if (isNullOrUndefined(this.model.email)) {
            return true;
        }
        if (isNullOrUndefined(this.model.license_plate)) {
            return true;
        }
        if (isNullOrUndefined(this.model.policy)) {
            return true;
        }
        if (isNullOrUndefined(this.model.closure)) {
            return true;
        }
        if (isNullOrUndefined(this.model.status)) {
            return true;
        }
        // if (isNullOrUndefined(this.model.reason)){
        //     return;
        // }

        return false;
    }

    getAttendance(id): void {
        this.loading.show();
        this.service.getAttendanceId(id).subscribe(
            data => {
                this.model = data as Attendance;
                this.request.attendance_id = data['id'];
                this.request.user_id = super.getUser().id;
                this.loading.hide();
                this.getAttendanceObservation();
            },
            error => {
                super.onError(error);
            });

    }

    onSave(): void {
        if (!this.validate()) {
            if (isNullOrUndefined(this.model.id)) {
                this.newAttendance();
            } else {
                this.editAttendance();
            }
        }

    }

    newAttendance(): void {
        this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Attendance;
                this.loading.hide();
                // this.router.navigate(['crm-register']);
            },
            error => {
                super.onError(error);
            });

    }

    editAttendance(): void {
        this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as Attendance;
                this.loading.hide();
                // this.router.navigate(['crm-register']);
            },
            error => {
                super.onError(error);
            });

    }

    newAttendanceObservation(): void {
        if (isNullOrUndefined(this.modelObservation.observation)) {
            return;
        }
        this.modelObservation.attendance_id = this.model.id;
        this.loading.show();
        this.service.createObservation(this.modelObservation).subscribe(
            data => {
                this.modelObservation = new Observation;
                this.loading.hide();
                this.getAttendanceObservation();
                // this.router.navigate(['crm-register']);
            },
            error => {
                super.onError(error);
            });

    }

    getAttendanceObservation(): void {
        this.loading.show();
        this.service.getObservation(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Observation[]);
                this.listObservation = data as Observation[];
                this.loading.hide();

            },
            error => {
                super.onError(error);
            });

    }

    deleteObservation(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir essa observação?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.deleteObservation(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Observação deletada com sucesso');
                        this.getAttendanceObservation();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });

    }

    getListStatus(): void {
        this.serviceEnum.getStatus().subscribe(
            data => {
                this.listStatus = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListReason(): void {
        this.serviceEnum.getReason().subscribe(
            data => {
                this.listReason = data;
            },
            error => {
                super.onError(error);
            });
    }

}
