
export class Casualty {

    id: String;
    reg_type: number;
    branch_number: number;
    product_number: number;
    casualty_number: number;
    casualty_order_number: number;
    endorsement_number: number;
    insured_cpf: String;
    insured_name: String;
    policy_number: number;
    risk_number: number;
    broker_code: number;
    status: String;
    cause_code: number;
    complaint_date: String;
    occurrence_date: String;
    discharge_date: String;
    discharge_cause_code: number;
    modification_date: String;
    modification_cause_code: number;
    reopening_date: String;
    reopening_cause_code: number;
    description: String;
    mark_refuse: String;
    date_end: String;
    cooperative_system_code: number;
    cooperative_system_name: String;
    cooperative_agency_code: String;
    cooperative_agency_name: String;
    pac_number: String;
    protocol_phone_number: number;
    hour: String;
    taker_code: number;
    taker_name: String;
    client_id: String;
    created_at: String;
    updated_at: String;
    licence_plate: String;
    insurer: string;
    cause_description: string;

    constructor() {

    }

}