import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {BaseComponent} from '../../base/basecomponent';
import {ReportRenovationService} from '../../service/report-renovation.service';
import {Router} from '@angular/router';
import {ReportRenovation} from '../../model/report-renovation';
import {NgxSpinnerService} from 'ngx-spinner';
import {Settings} from '../../settings';
import {isNullOrUndefined} from 'util';

@Component({
    selector: 'app-report-renovation',
    templateUrl: './report-renovation.component.html',
    styleUrls: ['./report-renovation.component.scss'],
    providers: [ReportRenovationService]
})
export class ReportRenovationComponent extends BaseComponent implements OnInit {

    displayedColumns = ['policy_number',  'vehicle',
        'model_year', 'license_plate', 'insured', 'taker', 'cpf',
        'validity_end'];

    dataSource: any;
    model: ReportRenovation = new ReportRenovation();

    request = {
        final_date: '',
        initial_date: '',
        insured: '',
        cpf: '',
        policy_number: '',
        license_plate: ''
    };


    data: any = [];

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ReportRenovationService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.resetFilter();
    }

    getList(): void {
        if (isNullOrUndefined(this.request.initial_date) || this.request.initial_date.length === 0) {
            super.openDialogAlert('Alert', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.final_date) || this.request.final_date.length === 0) {
            super.openDialogAlert('Alert', 'A data final é obrigatória!');
            return;
        }
        this.loading.show();
        this.service.getRenovation(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as ReportRenovation[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    // changePage(next: boolean): void {
    //     if (next) {
    //         if (this.dataSource.data.length < 20) {
    //             return;
    //         }
    //         this.request.page += 1;
    //         this.getList();
    //     } else {
    //         if (this.request.page === 1) {
    //             return;
    //         } else {
    //             this.request.page -= 1;
    //             this.getList();
    //         }
    //     }
    // }

    getExcel(): void {
        if (isNullOrUndefined(this.request.initial_date) || this.request.initial_date.length === 0) {
            super.openDialogAlert('Error', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.final_date) || this.request.final_date.length === 0) {
            super.openDialogAlert('Error', 'A data final é obrigatória!');
            return;
        }
         const token = localStorage.getItem('token') as String;
         let url = `${Settings.BASE_URL}/api/renovation/report/excel/?access_token=${token}`;
         url += ('&cpf=' + this.request.cpf);
         url += ('&license_plate=' + this.request.license_plate);
         url += ('&insured=' + this.request.insured);
         url += ('&policy_number=' + this.request.policy_number);
         url += ('&initial_date=' + this.request.initial_date);
         url += ('&final_date=' + this.request.final_date);
         window.open(url);
    }

    getPDF(): void {
        if (isNullOrUndefined(this.request.initial_date) || this.request.initial_date.length === 0) {
            super.openDialogAlert('Error', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.final_date) || this.request.final_date.length === 0) {
            super.openDialogAlert('Error', 'A data final é obrigatória!');
            return;
        }
        const token = localStorage.getItem('token') as String;
        let url = `${Settings.BASE_URL}/api/renovation/report/pdf/?access_token=${token}`;
        url += ('&cpf=' + this.request.cpf);
        url += ('&license_plate=' + this.request.license_plate);
        url += ('&insured=' + this.request.insured);
        url += ('&policy_number=' + this.request.policy_number);
        url += ('&initial_date=' + this.request.initial_date);
        url += ('&final_date=' + this.request.final_date);
        window.open(url);
    }

    resetFilter(): void {
        this.request.cpf = '';
        this.request.license_plate = '';
        this.request.insured = '';
        this.request.policy_number = '';

        const date = new Date(), y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        this.request.initial_date = firstDay.toISOString().slice(0, 10);
        this.request.final_date = lastDay.toISOString().slice(0, 10);
        this.dataSource = [];
    }

}
