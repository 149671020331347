import {Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, Output} from '@angular/core';

@Directive({
    selector: '[numberFormatter]'
})
export class NumberFormatterDirective {
    private input: HTMLInputElement;

    @Input()
    typeFormat: string;

    @Output()
    ngModelChange: EventEmitter<any> = new EventEmitter(false);

    constructor(private inputRef: ElementRef,
                private _ngZone: NgZone) {

        this.input = inputRef.nativeElement;
    }

    ngOnInit(): void {
        this.input.value = this.formatter(this.input.value);
    }

    /*@HostListener("keydown", ["$event.target.value"])
    onkeydown(value) {
      this.input.value = this.formatter(this.input.value);
      this._ngZone.run(() =>
        this.ngModelChange.emit(this.input.value));
    }*/


    @HostListener('keyup', ['$event.target.value'])
    onkeyup(value) {
        this.input.value = this.formatter(this.input.value);
        this._ngZone.run(() =>
            this.ngModelChange.emit(this.input.value));
    }

    @HostListener('focus', ['$event.target.value'])
    onFocus(value) {
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value) {
        this.input.value = this.formatter(this.input.value);
    }

    formatter(value): string {
        switch (this.typeFormat) {
            case 'money':
                return value.replace(/\D/g, '')
                    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
                    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '');
            case 'only_number':
                return value.replace(/\D/g, '');
            case 'phone':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{2})([0-9]{4,5})([0-9]{4})$/, '($1)$2-$3');
            case 'hour':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{2})([0-9]{2})$/, '$1:$2');
            case 'cpf-cnpj':
                return value.length > 16 ? value.replace(/\D/g, '')
                    .replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/, '$1.$2.$3/$4-$5') : value.replace(/\D/g, '')
                    .replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/, '$1.$2.$3-$4');
            case 'cnpj':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/, '$1.$2.$3/$4-$5');
            case 'cpf':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/, '$1.$2.$3-$4');
            case 'postal_code':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{5})([0-9]{3})$/, '$1-$2');
            case 'placa_mercosul':
                return value.replace(/([0-9]{3})([0-9]{2})([0-9]{2})$/, '$1-$2-$3');
            case 'date':
                return value.replace(/\D/g, '')
                    .replace(/([0-9]{2})([0-9]{2})([0-9]{4})$/, '$1/$2/$3');
            default:
                return value;
        }

    }
}
