import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Policy} from '../../model/policy';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../base/basecomponent';
import {PolicyService} from '../../service/policy.service';
import {EnumService} from '../../service/enum.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Settings} from '../../settings';
import {isNullOrUndefined, isUndefined} from 'util';

@Component({
    selector: 'app-register-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss'],
    providers: [PolicyService, EnumService]
})
export class PolicyComponent extends BaseComponent implements OnInit {

    displayedColumns = ['numberPolicy', 'insurer', 'type',
        'plate', 'client', 'document', 'date', 'validityInit'];
    dataSource: any;
    request = {
        insured: '',
        cpf: '',
        policy_number: '',
        license_plate: '',
        endorsement_type: '',
        validity_start: '',
        validity_end: '',
        // coverage_id: '',
        insurer_id: '',
        page: 1
    };

    listEndorsementType: any = [];
    listCoverage: any = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public service: PolicyService,
                public loading: NgxSpinnerService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        // this.route.params.subscribe(p => {
        //     if (!isUndefined(p['coverage'])) {
        //         this.request.coverage_id = p['coverage'];
        //     }
        // });

        this.getList();
        this.getListEndorsementType();
    }

    getList(): void {
        this.loading.show();
        this.service.getPolicy(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Policy[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.insured = '';
        this.request.cpf = '';
        this.request.policy_number = '';
        this.request.license_plate = '';
        this.request.validity_start = '';
        this.request.validity_end = '';
        this.request.endorsement_type = '';
        // this.request.coverage_id = '';
        this.request.page = 1;
        this.request.insurer_id = '';

        this.getList();
    }

    getExcel(): void {
        if (isNullOrUndefined(this.request.validity_start) || this.request.validity_start.length === 0) {
            super.openDialogAlert('Error', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.validity_end) || this.request.validity_end.length === 0) {
            super.openDialogAlert('Error', 'A data final é obrigatória!');
            return;
        }
        const token = localStorage.getItem('token') as String;
        let url = `${Settings.BASE_URL}/api/policy/excel/?access_token=${token}`;
        url += ('&insured=' + this.request.insured);
        url += ('&cpf=' + this.request.cpf);
        url += ('&policy_number=' + this.request.policy_number);
        url += ('&license_plate=' + this.request.license_plate);
        url += ('&endorsement_type=' + this.request.endorsement_type);
        url += ('&validity_start=' + this.request.validity_start);
        url += ('&validity_end=' + this.request.validity_end);
        // url += ('&coverage_id=' + this.request.coverage_id);
        url += ('&insurer_id=' + this.request.insurer_id);
        window.open(url);
    }

    getPDF(): void {
        if (isNullOrUndefined(this.request.validity_start) || this.request.validity_start.length === 0) {
            super.openDialogAlert('Error', 'A data inicial é obrigatória!');
            return;
        }

        if (isNullOrUndefined(this.request.validity_end) || this.request.validity_end.length === 0) {
            super.openDialogAlert('Error', 'A data final é obrigatória!');
            return;
        }
        const token = localStorage.getItem('token') as String;
        let url = `${Settings.BASE_URL}/api/policy/pdf/?access_token=${token}`;
        url += ('&insured=' + this.request.insured);
        url += ('&cpf=' + this.request.cpf);
        url += ('&policy_number=' + this.request.policy_number);
        url += ('&license_plate=' + this.request.license_plate);
        url += ('&endorsement_type=' + this.request.endorsement_type);
        url += ('&validity_start=' + this.request.validity_start);
        url += ('&validity_end=' + this.request.validity_end);
        // url += ('&coverage_id=' + this.request.coverage_id);
        url += ('&insurer_id=' + this.request.insurer_id);
        window.open(url);
    }

    onEdit(id): void {
        this.router.navigate(['policy-form', id]);
    }

    getListEndorsementType(): void {
        this.serviceEnum.getEndorsementType().subscribe(
            data => {
                this.listEndorsementType = data;
                // this.getListCoverage();
            },
            error => {
                super.onError(error);
            });
    }

    // getListCoverage(): void {
    //     this.serviceEnum.getCoverage().subscribe(
    //         data => {
    //             this.listCoverage = data;
    //         },
    //         error => {
    //             super.onError(error);
    //         });
    // }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
