import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Closure} from '../model/closure';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class ClosureService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getClosure(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/mensal/closure/list`;
        return this.http.post(url, request);
    }

    getClosureId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/mensal/closure/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: Closure): Observable<Object> {
        const url = `${this.BASE_URL}/api/mensal/closure/create`;
        return this.http
            .post(url, model, );
    }

    update(model: Closure): Observable<Object> {
        const url = `${this.BASE_URL}/api/mensal/closure/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/mensal/closure/remove/${id}`;
        return this.http
            .delete(url);
    }

}
