import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {Coverage} from '../../model/coverage';
import {CoverageService} from '../../service/coverage.service';
import {tap} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-register-coverage',
    templateUrl: './coverage.component.html',
    styleUrls: ['./coverage.component.scss'],
    providers: [CoverageService]
})
export class CoverageComponent extends BaseComponent implements OnInit {

    displayedColumns = ['coverage_code', 'description', 'insurer', 'delete'];
    dataSource: any;
    model: Coverage = new Coverage();
    request = {
        term: '',
        page: 1
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: CoverageService) {
        super(router, dialog, loading);
    }


    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getCoverage(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Coverage[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });

    }

    onEdit(id): void {
        this.router.navigate(['coverage-form', id]);

    }

    delete(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir essa cobertura?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Cobertura deletada com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });


    }

    newCoverage(): void {
        this.router.navigate(['coverage-form']);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
