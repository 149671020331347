import {Insurer} from './insurer';
import {Client} from './client';

export class ReportRenovation {

    color: string;
    client: Client;
    cpf: string;
    emission: string;
    insured: string;
    license_plate: string;
    model_year: string;
    policy_number: string;
    taker: string;
    validity_end: string;
    validity_start: string;
    vehicle: string;
    year: string;
    endorsement_type: EndorsementType;
    insurer: Insurer;

    constructor() {

    }

}

export class EndorsementType {
    description: string;
    id: string;
    type: string;

    constructor() {

    }
}


