import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/basecomponent';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {PropositionFormsService} from '../../service/proposition-forms.service';
import {PropositionForm} from '../../model/proposition-form';
import {Settings} from '../../settings';

@Component({
    selector: 'app-new-ics',
    templateUrl: './new-ics.component.html',
    styleUrls: ['./new-ics.component.scss'],
    providers: [PropositionFormsService]
})

export class NewIcsComponent extends BaseComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;
    columnsToDisplay = ['plate', 'name', 'created_at', 'pdf', 'delete'];
    dataSource: MatTableDataSource<any>;

    request = {
        type: 'new_ics',
        start: '',
        end: '',
        page: 1
    };

    constructor(public router: Router,
                public dialog: MatDialog,
                public service: PropositionFormsService,
                public loading: NgxSpinnerService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getList(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as PropositionForm[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    delete(id): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse formulaŕio?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.remove(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Formulário removido com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) { return; }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

    getPdf(id: string): void {
        const token = localStorage.getItem('token') as String;
        const url = `${Settings.BASE_URL}/api/proposition_forms/pdf/${id}?access_token=${token}`;
        window.open(url);
    }
}
