import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../base/basecomponent';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Controller, Proposition} from '../../../model/proposition';
import {isNullOrUndefined, isUndefined} from 'util';
import {PropositionService} from '../../../service/proposition.service';
import {EnumService} from '../../../service/enum.service';
import {InsurerService} from '../../../service/insurer.service';
import {Insurer} from '../../../model/insurer';
import {CoverageService} from '../../../service/coverage.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {UtilService} from '../../../service/util-service';
import {Address} from '../../../model/address';
import {Client} from '../../../model/client';

@Component({
    selector: 'app-proposal-form',
    templateUrl: './proposition-form.component.html',
    styleUrls: ['./proposition-form.component.scss'],
    providers: [PropositionService, EnumService, InsurerService, CoverageService, UtilService]
})
export class PropositionFormComponent extends BaseComponent implements OnInit {

    model: Proposition = new Proposition();
    modelController: Controller = new Controller();

    listStatus: any = [];
    listMovementType: any = [];
    listIncome: any = [];
    listBranch: any = [];
    listCoverage: any = [];
    listActivity: any = [];
    listInsurer: Insurer[] = [];

    listController: Controller[] = [];
    hideRisk = false;
    hideCoverage = false;
    hideVehicle = false;

    displayedColumns = ['name', 'cpf', 'ppe', 'delete'];
    dataSource: any;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    constructor(public router: Router,
                public dialog: MatDialog,
                public route: ActivatedRoute,
                public service: PropositionService,
                public serviceEnum: EnumService,
                public serviceInsurer: InsurerService,
                public loading: NgxSpinnerService,
                public utilService: UtilService,
                public serviceCoverage: CoverageService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.route.params.subscribe(p => {
            if (isUndefined(p['hash'])) {
                this.model = new Proposition();
            } else {
                this.getProposal(p['hash']);
            }
        });

        this.getListActivity();
        this.getListBranch();
        this.getListIncome();
        this.getListMovementType();
        this.getListStatus();
        this.getListInsurer();
        this.getListCoverage();
    }

    cancelProposal(): void {
        this.router.navigate(['register-proposition']);
    }

    onSelectedChange(): void {
        // this._mailService.toggleSelectedMail(this.mail.id);
    }

    validate(): boolean {
        // if (this.model.controllers == null || this.model.controllers.length === 0) {
        //     this.openDialogAlert('Error', 'É obrigatório ao menos 1 controlador!');
        //     return false;
        // }

        return true;
    }

    getProposal(id): void {
        this.loading.show();
        this.service.getProposalId(id).subscribe(
            data => {
                this.model = data as Proposition;
                this.listController = this.model.controllers;
                this.dataSource = new MatTableDataSource(this.listController);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    onSave(): void {
        console.log(this.model.id);
        this.model.controllers = this.listController;
        if (!isNullOrUndefined(this.model.license_plate)) {
            this.model.license_plate = this.model.license_plate.toUpperCase();
        }
        if (this.validate()) {
            if (isNullOrUndefined(this.model.id) || this.model.id.length === 0) {
                this.newProposal();
            } else {
                this.editProposal();
            }
        }
    }

    newProposal(): void {
        this.loading.show();
        this.service.create(this.model).subscribe(
            data => {
                this.model = data as Proposition;
                this.loading.hide();
                this.openDialogAlert('Success', 'Proposta cadastrada com sucesso!');
            },
            error => {
                super.onError(error);
            });

    }

    editProposal(): void {
        this.loading.show();
        this.service.update(this.model).subscribe(
            data => {
                this.model = data as Proposition;
                this.loading.hide();
                this.openDialogAlert('Success', 'Proposta alterada com sucesso!');
            },
            error => {
                super.onError(error);
            });

    }

    addController(): void {
        this.listController.push(this.modelController);
        this.dataSource = new MatTableDataSource(this.listController);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.modelController = new Controller();
    }

    removeController(name): void {
        const index = this.listController.findIndex(a => a.name === name);
        this.listController.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.listController);
    }

    getListStatus(): void {
        this.serviceEnum.getStatus().subscribe(
            data => {
                this.listStatus = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListCoverage(): void {
        this.serviceCoverage.getCoverage('').subscribe(
            data => {
                this.listCoverage = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListMovementType(): void {
        this.serviceEnum.getMovementType().subscribe(
            data => {
                this.listMovementType = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListBranch(): void {
        this.serviceEnum.getBranch().subscribe(
            data => {
                this.listBranch = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListIncome(): void {
        this.serviceEnum.getIncome().subscribe(
            data => {
                this.listIncome = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListActivity(): void {
        this.serviceEnum.getActivity().subscribe(
            data => {
                this.listActivity = data;
            },
            error => {
                super.onError(error);
            });
    }

    getListInsurer(): void {
        this.serviceInsurer.getList('').subscribe(
            data => {
                this.listInsurer = data as Insurer[];
            },
            error => {
                super.onError(error);
            });
    }

    copyInsuredStipulating(): void {
        this.model.stipulating_cpf = this.model.cpf_cnpj;
        this.model.stipulating_name = this.model.insured_name;
    }

    copyInsuredRisk(): void {
        this.model.risk_beneficiary_name = this.model.insured_name;
        this.model.risk_beneficiary_cpf_cnpj = this.model.cpf_cnpj;
        this.model.risk_zip_code = this.model.zip_code;
        this.model.risk_address = this.model.address;
        this.model.risk_district_city = this.model.district + ' / ' + this.model.city;
        this.model.risk_state = this.model.state;
    }

    getAddress(zip_code: string, risk = false): void {
        this.loading.show();
        this.utilService.addressByZipCode(zip_code).subscribe(
            data => {
                this.loading.hide();
                const ad = data as Address;

                if (risk) {
                    this.model.risk_address = ad.address;
                    this.model.risk_district_city = ad.district + ' / ' + ad.city;
                    this.model.risk_state = ad.state;
                    this.model.risk_zip_code = ad.zip_code;
                } else {
                    this.model.address = ad.address;
                    this.model.district = ad.district;
                    this.model.city = ad.city;
                    this.model.state = ad.state;
                    this.model.zip_code = ad.zip_code;
                }
            },
            error => {
                super.onError(error);
            });
    }

    getByCpf(): void {
        this.loading.show();
        this.service.getByCpf(this.model.cpf_cnpj).subscribe(
            data => {
                const c = data as Client;
                // this.model = data as Attendance;
                this.model.client_id = c.id;
                this.model.insured_name = c.name;
                this.model.address = c.residential_address;
                this.model.zip_code = c.residential_zip_code;
                this.model.number = c.residential_number;
                this.model.city = c.residential_city;
                this.model.district = c.residential_district;
                this.model.state = c.residential_state;
                this.model.email = c.email;
                this.model.insured_gender = c.gender;
                this.model.insured_rg = c.rg;
                this.model.insured_civil_status = c.civil_status;
                this.model.insured_birthday = c.birthday;
                if (!isNullOrUndefined(c.ddd) && !isNullOrUndefined(c.phone)) {
                    this.model.phone = '(' + c.ddd + ')' + c.phone;
                } else {
                    this.model.phone = '';
                }
                if (!isNullOrUndefined(c.residential_ddd) && !isNullOrUndefined(c.residential_phone)) {
                    this.model.residential_phone = '(' + c.residential_ddd + ')' + c.residential_phone;
                } else {
                    this.model.residential_phone = '';
                }
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    recordPolicy(): void {
        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja Gerar Registro de Apólice?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.generate(this.model.id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', data['message']);
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });
    }

}
