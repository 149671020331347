import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import {Injectable} from '@angular/core';

@Injectable()
export class UtilService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    addressByZipCode(zip_code: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/util/address_by_zip_code`;
        return this.http
            .post(url, `{"zip_code":"${zip_code}"}`);
    }
}
