import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Client} from '../../model/client';
import {BaseComponent} from '../../base/basecomponent';
import {Router} from '@angular/router';
import {ClientService} from '../../service/client.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-crm-register',
    templateUrl: './crm-register.component.html',
    styleUrls: ['./crm-register.component.scss'],
    providers: [ClientService]
})
export class CrmRegisterComponent extends BaseComponent implements OnInit {

    displayedColumns = ['cpf', 'name', 'email',
        'gender', 'delete'];
    dataSource: MatTableDataSource<any>;
    model: Client = new Client();

    request = {
        name: '',
        cpf: '',
        email: '',
        gender: '',
        civil_status: '',
        state: '',
        city: '',
        page: 1
    };


    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: ClientService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
        this.loading.show();
        this.service.getClient(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as Client[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.cpf = '';
        this.request.name = '';
        this.request.email = '';
        this.request.gender = '';
        this.request.civil_status = '';
        this.request.state = '';
        this.request.city = '';
        this.request.page = 1;

        this.getList();
    }

    onEdit(id): void {
        this.router.navigate(['crm-form', id]);

    }

    delete(id): void {

        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir esse cliente?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Cliente deletado com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });

    }

    newClient(): void {
        this.router.navigate(['crm-form']);
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) {
                return;
            }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
