export class User {
    user_id: string;
    id: string;
    code: string;
    name: string;
    email: string;
    admin_type: string;
    password: string;
    type: string;
    created_at: string;
    last_access: string;
    photo: string;
    access: Access[];

    constructor() {

    }

}

export class Access {

    id: string;
    name: string;
    permissions: Permission[];

    constructor() {
        this.permissions = [];
    }

}

export class Permission {

    id: string;
    description: string;
    access: Options;

    constructor() {
        this.access = new Options();
    }

}

export class Options {

    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
    all: boolean;

    constructor() {
        this.create = false;
        this.read = false;
        this.update = false;
        this.delete = false;
        this.all = false;
    }
}
