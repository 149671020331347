import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSort, MatTableDataSource} from '@angular/material';
import {Policy} from '../../model/policy';
import {WithoutPatternPropositionService} from '../../service/without-pattern-proposition.service';
import {Proposition} from '../../model/proposition';
import {Router} from '@angular/router';
import {BaseComponent} from '../../base/basecomponent';
import {EnumService} from '../../service/enum.service';
import {tap} from 'rxjs/operators';
import {WithoutPatternPolicy} from '../../model/withoutPatternPolicy';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-register-non-standart-policy',
  templateUrl: './non-standart-policy.component.html',
  styleUrls: ['./non-standart-policy.component.scss'],
    providers: [WithoutPatternPropositionService, EnumService]
})
export class NonStandartPolicyComponent extends BaseComponent implements OnInit  {

    displayedColumns = ['numberPolicy', 'type',
        'plate', 'client', 'document', 'validityInit', 'validityEnd', 'delete'];
    dataSource: any;
    model: Policy = new Policy();

    listEndorsementType: any = [];

    request = {
        insured: '',
        cpf_cnpj: '',
        policy_number: '',
        license_plate: '',
        validity_start: '',
        validity_end: '',
        page: 1
    };

    @ViewChild(MatSort) sort: MatSort;

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }


    constructor(public router: Router,
                public dialog: MatDialog,
                public loading: NgxSpinnerService,
                public service: WithoutPatternPropositionService,
                public serviceEnum: EnumService) {
        super(router, dialog, loading);
    }

    ngOnInit(): void {
        this.getList();
    }

    getList(): void {
            this.loading.show();
        this.service.getWithoutPatternProposal(this.request).subscribe(
            data => {
                this.dataSource = new MatTableDataSource(data as WithoutPatternPolicy[]);
                this.dataSource.sort = this.sort;
                this.loading.hide();
            },
            error => {
                super.onError(error);
            });
    }

    resetFilter(): void {
        this.request.insured = '';
        this.request.cpf_cnpj = '';
        this.request.policy_number = '';
        this.request.license_plate = '';
        this.request.validity_start = '';
        this.request.validity_end = '';
        this.request.page = 1;

        this.getList();
    }

    onEdit(row): void {
        this.router.navigate(['non-standart-policy-form/', row.id]);
    }

    newProposal(): void {
        this.router.navigate(['non-standart-policy-form']);
    }

    delete(id): void {

        const myDialogRef = this.openDialogAlert('Alert', 'Você tem certeza que deseja excluir essa apólice fora de padrão?');
        myDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                    this.loading.show();
                this.service.delete(id).subscribe(
                    data => {
                        this.loading.hide();
                        this.openDialogAlert('Success', 'Apólice fora de padrão deletado com sucesso');
                        this.getList();
                    },
                    error => {
                        super.onError(error);
                    });
            }
        });

    }

    getListEndorsementType() {
        this.serviceEnum.getEndorsementType().subscribe(
            data => {
                this.listEndorsementType = data;
            },
            error => {
                super.onError(error);
            });
    }

    changePage(next: boolean): void {
        if (next) {
            if (this.dataSource.data.length < 20) { return; }
            this.request.page += 1;
            this.getList();
        } else {
            if (this.request.page === 1) {
                return;
            } else {
                this.request.page -= 1;
                this.getList();
            }
        }
    }

}
