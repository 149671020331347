import {Casualty} from './casualty';
import {Attendance} from './attendance';
import {Policy} from './policy';

export class Client {

    id: string;
    name: string;
    birthday: string;
    cpf: string;
    rg: string;
    gender: string;
    phone1: string;
    phone2: string;
    phone3: string;
    maritalStatus: string;
    address: string;
    number: string;
    city: string;
    district: string;
    state: string;
    email: string;
    attendance: Attendance[];
    casualty: Casualty[];
    zip_code: string;
    ddd: string;
    phone: string;
    civil_status: string;
    observation: string;
    ppex: number;
    income_code: string;
    activity_code: string;
    client_origin_code: number;
    residential_address: string;
    residential_city: string;
    residential_ddd: string;
    residential_district: string;
    residential_number: string;
    residential_phone: string;
    residential_state: string;
    residential_zip_code: string;
    policies: Policy[];

    constructor() {

    }

}
