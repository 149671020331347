import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class ReportRenovationService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getRenovation(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/renovation/report`;
        return this.http.post(url, request);
    }

}
