import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Proposition} from '../model/proposition';
import {WithoutPatternPolicy} from '../model/withoutPatternPolicy';

@Injectable()
export class WithoutPatternPropositionService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getWithoutPatternProposal(request) {
        const url = `${this.BASE_URL}/api/without-pattern-policy/proposition/list`;
        return this.http.post(url, request);
    }

    getWithoutPatternProposalId(id) {
        const url = `${this.BASE_URL}/api/without-pattern-policy/proposition/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: WithoutPatternPolicy) {
        const url = `${this.BASE_URL}/api/without-pattern-policy/proposition/create`;
        return this.http.post(url, model);
    }

    update(model: WithoutPatternPolicy) {
        const url = `${this.BASE_URL}/api/without-pattern-policy/proposition/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string) {
        const url = `${this.BASE_URL}/api/without-pattern-policy/proposition/delete${id}`;
        return this.http
            .delete(url);
    }


}