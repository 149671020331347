import {Controller} from './proposition';
import {Insurer} from './insurer';
import {Client} from './client';
import {Coverage} from './coverage';

export class Policy {

    blocked: number;
    branch_id: string;
    chassis: string;
    client_id: string;
    color: number;
    commercial_address: string;
    commercial_city: string;
    commercial_complement: string;
    commercial_ddd: string;
    commercial_district: string;
    commercial_number: number;
    commercial_phone: string;
    commercial_state: string;
    commercial_zip_code: number;
    controllers: Controller[];
    correspondence_address: string;
    correspondence_city: string;
    correspondence_complement: string;
    correspondence_ddd: string;
    correspondence_district: string;
    correspondence_number: string;
    correspondence_phone: string;
    correspondence_state: string;
    correspondence_zip_code: string;
    coverage_id: string;
    cpf: string;
    created_at: string;
    ddd: string;
    denial_date: string;
    emission: string;
    endorsement_number: number;
    endorsement_type_id: string;
    fax_ddd: string;
    fax_phone: string;
    id: string;
    insurer: Insurer;
    insurer_id: string;
    license_plate: string;
    modality_code: string;
    model_year: string;
    mx2_code: string;
    order: string;
    phone: string;
    policy_number: string;
    premium: number;
    proposition_number: string;
    residential_address: string;
    residential_city: string;
    residential_complement: string;
    residential_ddd: string;
    residential_district: string;
    residential_number: string;
    residential_phone: string;
    residential_state: string;
    residential_zip_code: string;
    stipulating: string;
    stipulating_cpf: number;
    updated: number;
    updated_at: string;
    validity_end: string;
    validity_start: string;
    vehicle: string;
    year: string;
    zero_km: string;
    name: string;

    email: string;
    birthday: string;
    gender: string;
    civil_status: string;
    endorsement: string;
    insurer_code: string;
    branch_number: string;
    importation_id: string;
    old_id: string;
    ppe: string;
    activity_code: string;
    income_code: string;
    ppex: string;
    taker: string;
    insured: string;

    endorsement_type: EndorsementType;
    branch: Branch;
    // coverage: Coverage;
    client: Client;

    profiles: Profile[];
    coverages: PolicyCoverage[];

    constructor() {
        this.controllers = [];
        this.endorsement_type = new EndorsementType();
        this.branch = new Branch();
        // this.coverage = new Coverage();
        this.client = new Client();
        this.insurer = new Insurer();
        this.profiles = [];
        this.coverages = [];
    }

}


export class EndorsementType {
    id: string;
    name: string;

    constructor() {
        this.id = '';
        this.name = '';
    }
}

export class Branch {
    id: string;
    name: string;

    constructor() {
        this.id = '';
        this.name = '';
    }
}

export class Profile {
    id: string;
    question: string;
    answer: string;
    created_at: string;
    updated_at: string;

    constructor() {
    }
}

export class PolicyCoverage {
    id: string;
    policy_id: string;
    coverage_id: string;
    created_at: string;
    updated_at: string;
    coverage: Coverage;

    constructor() {
    }
}
