import {environment} from '../environments/environment';


export class Settings {
    public static CLIENTS_FILE_URL = 'https://service.mx2seguros.com.br/files/atualizar_clientes.xlsx';
    public static BASE_URL = environment.base_url;
    // public static BASE_URL = 'http://mx2.local';

    constructor() {
    }

}
