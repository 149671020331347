import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Proposition} from '../model/proposition';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class PropositionService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getProposal(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/list`;
        return this.http.post(url, request);
    }

    getProposalId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: Proposition): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/create`;
        return this.http.post(url, model);
    }

    update(model: Proposition): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/remove/${id}`;
        return this.http.delete(url);
    }

    generate(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/policy/proposition/generate/${id}?XDEBUG_SESSION_START=PHPSTORM`;
        return this.http.post(url, id);
    }

    getByCpf(cpf: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/client/get_by_cpf`;
        return this.http.post(url, `{"cpf": "${cpf}"}`);
    }

}
