import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {User} from '../model/user';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class UserService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getUser(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/list`;
        return this.http.post(url, request);
    }

    getPermissions(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/permission/list`;
        return this.http.post(url, request);
    }

    getUserId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: User): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/register`;
        return this.http
            .post(url, model);
    }

    update(model: User): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/remove/${id}`;
        return this.http
            .delete(url);
    }

    login(email: string, password: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/login`;
        const data = {email: email, password: password};
        return this.http
            .post(url, data);
    }

    forgot(email: String): Observable<Object> {
        const url = `${this.BASE_URL}/api/forgot/${email}`;
        return this.http
            .get(url);
    }

    password(request: any): Observable<Object> {
        const url = `${this.BASE_URL}/api/admin/password`;
        return this.http
            .post(url, request);
    }

    list(request: any): Observable<Object> {
        const url = `${this.BASE_URL}/api/user/list`;
        return this.http
            .post(url, request);
    }


}
