import {Injectable} from '@angular/core';
import {ServiceBase} from './service-base';
import {HttpClient} from '@angular/common/http';
import {Coverage} from '../model/coverage';
import {Observable} from 'rxjs/Rx';

@Injectable()
export class CoverageService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getCoverage(request): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/list`;
        return this.http.post(url, request);
    }

    getCoverageId(id): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/get`;
        const data = {id: id};
        return this.http.post(url, data);
    }

    create(model: Coverage): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/create`;
        return this.http
            .post(url, model);
    }

    update(model: Coverage): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/update`;
        return this.http
            .put(url, model);
    }

    delete(id: string): Observable<Object> {
        const url = `${this.BASE_URL}/api/coverage/remove/${id}`;
        return this.http
            .delete(url);
    }

}
